import "../styles/style.scss";
import { api } from "./lib/api";

let gameId;
let allowedChoices;

// 오류가 있을 때 초기 화면으로 돌아가게 만드는 화면
function checkPermission(gameId) {
  api("get", `games/ongoings/${gameId}`, undefined, (res) => {
    if (res && res.msg == "ERROR") {
      window.location.href = "./index.html";
      console.log(res);
    }
  });
}

window.onload = () => {
  const url = new URL(window.location.href);

  const searchParams = url.searchParams;
  gameId = searchParams.get("gameId");
  checkPermission(gameId);

  api("get", `games/ongoings/${gameId}`, undefined, (res) => {
    if (res && res.msg == "OK") {
      document.querySelector(".survey__company").innerText =
        res.result.data.company;
      document.querySelector(".survey__gameName").innerText =
        res.result.data.title;
    }
  });

  api("get", `users/me/details`, undefined, (res) => {
    if (res && res.msg == "OK") {
      document.querySelector(".user__name").innerText =
        res.result.data.nickname;
    }
  });

  api("get", `testers/surveys/${gameId}`, undefined, (res) => {
    if (res && res.msg == "OK") {
      if (res.result.data) {
        const resultData = res.result.data;
        allowedChoices = new Array(resultData.multipleCount);
        setSurveyItem(resultData.multipleQuestions);
        guideToggle();
        document.body.style.display = "block";
      }
    }
  });

  const guideToggle = () => {
    const guideBtn = document.querySelector(".guide__btn");

    guideBtn.addEventListener("click", (e) => {
      e.preventDefault();

      if (!guideBtn.classList.contains("is-active")) {
        guideBtn.classList.add("is-active");
      } else {
        guideBtn.classList.remove("is-active");
      }
    });
  };
};

// 객관식 문제 셋팅
function setSurveyItem(questions) {
  const list = document.querySelector(".surveyList");

  questions.forEach((question, index) => {
    allowedChoices[index] = {
      maxCount: question.allowedChoices,
      count: 0,
    };
    list.innerHTML += `<div data-val="${
      question.questionId
    }" class="surveyList__item">
      <h3 class="surveyList__tit">Q${index + 1}. ${question.content}</h3>
      <ul class="surveyList__answer">
      </ul>
    </div>`;
  });

  setChoiceItem(questions);

  list.innerHTML += `<div class="survey__btn">
                  <button id="btnNext" type="button" class="btn is-round is-fill-${
                    !onCheckBlank() ? "gray" : "blue"
                  }" ${!onCheckBlank() ? "disabled" : ""}>
                    다음
                  </button>
                </div>`;

  document.querySelector("#btnNext").onclick = () => {
    onSendData();
  };

  document.querySelectorAll(".surveyList__answer li").forEach((li) => {
    li.onclick = (e) => {
      if (e.target.type == "checkbox") {
        onClickCheckBox(e.target);
      }
    };
  });
}

// 객관식 보기 셋팅
function setChoiceItem(questions) {
  document
    .querySelectorAll(".surveyList__answer")
    .forEach((answerList, index) => {
      questions[index].choices.forEach((choice, choiceIndex) => {
        choice.archive && allowedChoices[index].count++;
        answerList.innerHTML += `<li id="chk_${index + 1}_${choiceIndex + 1}">
            <label data-val="${choice.id}" class="surveyChk" >
              <input type="checkbox" class="surveyChk__input" ${
                choice.archive ? "checked" : ""
              } />
              <span class="surveyChk__style"></span>
              <span class="surveyChk__text">${choice.content}</span>
            </label>
          </li>`;
      });
    });
}

// 체크 박스 클릭
function onClickCheckBox(target) {
  const id = target.parentElement.parentElement.id;
  const multipleIndex = id.split("_")[1];
  const allowedChoice = allowedChoices[multipleIndex - 1];
  const maxCount = allowedChoice.maxCount;
  if (target.getAttribute("checked") != null) {
    target.removeAttribute("checked");
    allowedChoices[multipleIndex - 1].count--;
  } else {
    allowedChoices[multipleIndex - 1].count = 0;
    document.querySelectorAll("li").forEach((li) => {
      if (li.id.split("_")[1] == id.split("_")[1]) {
        if (li.id.split("_")[2] == id.split("_")[2]) {
          target.setAttribute("checked", "");
          allowedChoices[multipleIndex - 1].count = 1;
        } else {
          li.querySelector("input").removeAttribute("checked");
          li.querySelector("input").checked = false;
        }
      }
    });
    // if (maxCount > allowedChoice.count) {
    //   target.setAttribute("checked", "");
    //   allowedChoices[multipleIndex - 1].count++;
    // } else {
    //   target.checked = false;
    // }
  }
  const btnNext = document.querySelector("#btnNext");
  if (!onCheckBlank()) {
    btnNext.className = "btn is-round is-fill-gray";
    btnNext.disabled = true;
  } else {
    btnNext.className = "btn is-round is-fill-blue";
    btnNext.disabled = false;
  }
}

// 체크 안한 문항 있는지 확인
function onCheckBlank(cb) {
  let flagCheck = true;
  for (let index = 0; index < allowedChoices.length; index++) {
    const allowedChoice = allowedChoices[index];
    if (allowedChoice.count == 0) {
      // alert("선택 안한 문항 있을 경우");
      flagCheck = false;
      break;
    }
  }
  // if (flagCheck && cb) {
  //   cb(flagCheck);
  // }
  return flagCheck;
}

// 다음 버튼 클릭
function onSendData() {
  const formData = new FormData();
  const sendData = {};
  sendData["gameId"] = parseInt(gameId);
  sendData["multipleAnswers"] = [];
  sendData["subjectiveAnswers"] = [];
  sendData["photoAnswers"] = [];

  document.querySelectorAll(".surveyList__item").forEach((item, index) => {
    const questionId = item.getAttribute("data-val");
    sendData["multipleAnswers"].push({
      questionId: parseInt(questionId),
      choiceIds: [],
    });
    item.querySelectorAll(".surveyChk__input").forEach((li) => {
      li.checked &&
        sendData["multipleAnswers"][index].choiceIds.push(
          parseInt(li.parentElement.getAttribute("data-val"))
        );
    });
  });

  const json = JSON.stringify(sendData);

  formData.append(
    "data",
    new Blob([json], {
      type: "application/json",
    })
  );

  api("post", `testers/archives/burningBeaver`, formData, (res) => {
    if (res && res.msg == "OK") {
      localStorage.setItem("survey:sendData", json);
      window.location.href = `./survey_2.html?gameId=${gameId}`;
    }
  });
}
